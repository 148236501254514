"use client";

import React from "react";
import { Typography } from "@mui/material";
import { motion } from "framer-motion";

// Define container animation variants
const containerVariants = {
  hidden: { opacity: 0, scale: 0.97 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: { duration: 1.2, ease: [0.25, 0.1, 0.25, 1] },
  },
};

// Define inner content animation variants
const innerVariants = {
  hidden: { opacity: 0, y: 30 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { duration: 1.6, ease: [0.22, 1, 0.36, 1] },
  },
};

// Define the text shadow animation for the span
const textAnimation = {
  animate: {
    textShadow: [
      "0 0 0px rgba(255,255,255,0.05)",
      "0 0 12px rgba(255,255,255,0.12)",
      "0 0 0px rgba(255,255,255,0.05)",
    ],
    transition: {
      duration: 4,
      repeat: Infinity,
      ease: "easeInOut",
    },
  },
};

// Define static styles for the container
const containerStyle = {
  minHeight: "100vh",
  background: "black",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "2rem",
};

const DotsLoading = React.memo(() => {
  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={containerVariants}
      style={containerStyle}
    >
      <motion.div initial="hidden" animate="visible" variants={innerVariants}>
        <motion.span {...textAnimation}>
          <Typography
            variant="h2"
            sx={{
              fontFamily: "'Montserrat', sans-serif",
              fontWeight: 700,
              textTransform: "uppercase",
              letterSpacing: "0.06em",
              fontSize: {
                xs: "2.8rem",
                sm: "3.6rem",
                md: "4.5rem",
              },
              color: "white",
              textAlign: "center",
            }}
          >
            LEHUBS
          </Typography>
        </motion.span>
      </motion.div>
    </motion.div>
  );
});

export default DotsLoading;
